<script>
export default {
  name: 'Boolean',
  props: {
    value: {
      type: [Boolean],
      required: true,
    },
  },
  computed: {
    formattedValue() {
      return this.value ? 'Yes' : 'No';
    },
  },
};
</script>

<template lang="pug">
span {{ formattedValue }}
</template>
